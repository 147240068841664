const API = useApi();
const idempotencyKey = useIdempotencyKey();

// eslint-disable-next-line require-jsdoc
export const useSberIdApi = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    getAuthorizeUrl() {
      return API.Get(`/auth/sber_id/authorize_url`, {});
    },

    // eslint-disable-next-line require-jsdoc
    authorize(code) {
      const key = idempotencyKey.generate(code, 7);

      return API.Post(`/auth/sber_id/authorize`, { code: code, idempotency_key: key });
    },

    ssoAuthorize(params) {
      return API.Get(`/auth/sber_id/sso_url?${params}`);
    },
  };
};
